import React from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Reg from "./Regisiter/Reg";

import { NhostClient, NhostProvider } from "@nhost/react";
import HomePage from "./screens/HomePage/Homepage";

const nhost = new NhostClient({
  subdomain: process.env.REACT_APP_NHOST_SUBDOMAIN,
  region: process.env.REACT_APP_NHOST_REGION,
});

const App = () => {
  return (
    <NhostProvider nhost={nhost}>
      <BrowserRouter>
        <div>
          {/* Use the Routes component as the parent */}
          <Routes>
            {/* Define routes using the Route component */}
            <Route path="/" element={<HomePage />} />
          </Routes>
        </div>
      </BrowserRouter>
    </NhostProvider>
  );
};

export default App;
