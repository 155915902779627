import { Navbar, Nav } from "rsuite";
import HomeIcon from "@rsuite/icons/legacy/Home";
import CogIcon from "@rsuite/icons/legacy/Cog";
import React from "react";
import FacebookOfficialIcon from "@rsuite/icons/legacy/FacebookOfficial";
import { IconButton, ButtonToolbar, ButtonGroup } from "rsuite";
import LinkedinIcon from "@rsuite/icons/legacy/Linkedin";

const CustomNavbar = ({ onSelect, activeKey, ...props }) => {
  return (
    <Navbar {...props}>
      <Navbar.Brand href="#">D</Navbar.Brand>
      <Nav onSelect={onSelect} activeKey={activeKey}>
        <Nav.Item eventKey="1" icon={<HomeIcon />}>
          Home
        </Nav.Item>
        <Nav.Item eventKey="2">News</Nav.Item>
        <Nav.Item eventKey="3">Products</Nav.Item>
        <Nav.Menu title="About">
          <Nav.Item eventKey="4">Company</Nav.Item>
          <Nav.Item eventKey="5">Team</Nav.Item>
          <Nav.Item eventKey="6">Contact</Nav.Item>
        </Nav.Menu>
      </Nav>
      <Nav pullRight>
        <Nav.Item eventKey="6">
          <IconButton
            icon={<FacebookOfficialIcon spin />}
            color="white"
            appearance="primary"
            circle
            style={{
              border: "1px solid white",
            }}
          />
        </Nav.Item>
        <Nav.Item eventKey="6">
          <IconButton
            icon={<LinkedinIcon spin />}
            color="white"
            appearance="primary"
            circle
            style={{
              border: "1px solid white",
            }}
          />
        </Nav.Item>
      </Nav>
    </Navbar>
  );
};

const TabBar = () => {
  const [activeKey, setActiveKey] = React.useState(null);

  return (
    <>
      <CustomNavbar
        appearance="inverse"
        activeKey={activeKey}
        onSelect={setActiveKey}
      />
    </>
  );
};

export default TabBar;
