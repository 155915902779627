import React from "react";
import data from "../../data/info.json";
import { TypeAnimation } from "react-type-animation";
import TabBar from "../TopNav/TopNav";
import banner from "../../Assets/banner.jpg";
import about from "../../Assets/about.png";

const HomePage = () => {
  return (
    <div>
      <TabBar />

      <div className="container d-flex justify-content-center align-items-center px-3">
        <div>
          <h3
            className="px-3 mt-3"
            style={{
              textShadow: "4px -7px 8px rgba(0,0,0,0.15)",
            }}
          >
            Welcome to Dinesh's World of Problem Solving and Application
            Development!
          </h3>
          <TypeAnimation
            sequence={[
              // Same substring at the start will only be typed out once, initially
              "I'am a Frontend dev",
              1000, // wait 1s before replacing "Mice" with "Hamsters"
              "I'am a Backend dev",
              1000,
              "I Love Javascript",
              1000,
              "I Love React js",
              1000,
              "I Love Node js",
              1000,
              "I'am a Full Stack dev",
              1000,
            ]}
            wrapper="span"
            speed={30}
            style={{
              fontSize: "2.5em",
              display: "inline-block",
              color: "#007BFF",
            }}
            repeat={Infinity}
          />
        </div>
        <div style={{ width: "70%", height: "auto" }}>
          <img
            src={banner}
            alt=""
            style={{
              width: "-webkit-fill-available",
            }}
          />
        </div>
      </div>

      <div className="container d-flex justify-content-center align-items-center px-3 px3">
        <div style={{ width: "50%", height: "auto" }}>
          <img
            src={about}
            alt=""
            style={{
              width: "-webkit-fill-available",
            }}
          />
        </div>
        <section className="px-3">
          <h2>About Me</h2>
          <p>
            Hello there! I'm Dinesh Murthy, a passionate problem solver and
            application developer with over 5 years of experience in React.js
            and Node.js. My journey in the world of web development has been an
            exciting one, and I'm thrilled to have you join me on this
            adventure.
          </p>
          <p>
            My love for coding and creating innovative solutions led me to
            specialize in React.js and Node.js, two powerful technologies that
            enable me to build cutting-edge web applications with efficiency and
            elegance. I've honed my skills through numerous projects and
            challenges, always striving to deliver the best possible user
            experiences.
          </p>
          <p>
            Being a lifelong learner, I'm constantly exploring new technologies
            and trends, seeking to stay ahead in the ever-evolving world of web
            development. Embracing the latest updates and best practices, I
            ensure that my projects are not only robust and scalable but also at
            the forefront of innovation.
          </p>
          <p>
            But my passion for problem-solving doesn't stop with web
            development. Just like in chess, where each move requires strategic
            thinking, I approach coding with a similar mindset, carefully
            planning and executing every aspect of my applications.
          </p>
        </section>
      </div>
    </div>
  );
};

export default HomePage;
